/* clase para el loader */
.spinner {
  position: absolute;
  height: 100vh; 
  width: 100%; 
  background-color: rgba(0, 0, 0, 0.313);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.loader {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  display: inline-block !important;
  position: relative !important;
  border: 3px solid !important;
  border-color: #FFF #FFF transparent transparent !important;
  box-sizing: border-box !important;
  animation: rotation 1s linear infinite !important;
}
.loader::after,
.loader::before {
  content: '' !important;  
  box-sizing: border-box !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  border: 3px solid !important;
  border-color: transparent transparent #FF3D00 #FF3D00 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  box-sizing: border-box !important;
  animation: rotationBack 0.5s linear infinite !important;
  transform-origin: center center !important;
}
.loader::before {
  width: 32px !important;
  height: 32px !important;
  border-color: #FFF #FFF transparent transparent !important;
  animation: rotation 1.5s linear infinite !important;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    